import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";

const Test = () => {
    const location = useLocation();
    console.log(location.search);
    const [url, setUrl] = useState("");
    useEffect(() => {
        setUrl("https://www.kraftshala.com/landing-page/" + (!!location.search ? location.search : (!!location.hash ? location.hash: "")));
    }, [location.search]);
    return (
        <iframe title="Kraftshala" src={url} />
    )
}

export default Test;