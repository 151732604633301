import { Route, Routes, Navigate } from "react-router-dom";
import MarketingLaunchpad from "../Pages/MarketingLaunchpad";
import Test from "../Pages/Test";

const RoutingContainer = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Navigate to={"/digital-marketing-launchpad"}/>} />
            <Route exact path="/digital-marketing-launchpad" element={<Test/>} />
        </Routes>
    )
};

export default RoutingContainer;