import { BrowserRouter } from "react-router-dom";
import RoutingContainer from "./Routes";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <RoutingContainer />
            </BrowserRouter>
        </div>
    );
}

export default App;
